import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { CalendarIcon, CheckCircleIcon, LocationMarkerIcon, OfficeBuildingIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/solid'
//import { QuestionMarkCircleIcon } from '@heroicons/react/solid'
import Leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, useMapEvents, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

export default function Attendance(props) {
    document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'visible') {
        window.location.reload();
      }
    });

   const { user } = props;
    const markerRef = useRef(null)
    const [position, setPosition] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isInitialLoaded, setIsInitialLoaded] = useState(false)
    const [isInOffice, setIsInOffice] = useState(false)
    const [isTimeValid, setIsTimeValid] = useState(false)
    const [note, setNote] = useState(null)
    const [otherNote, setOtherNote] = useState(null)
    const [isWaitForCheckout, setIsWaitForCheckout] = useState(false);
    const [isCheckedOut, setIsCheckedOut] = useState(false);
    const [attendanceId, setAttendanceId] = useState(null);
    const [today, setToday] = useState(null);
    const [attendance, setAttendance] = useState(null);
    const [validCheckInTime, setValidCheckInTime] = useState(null)
    const [validCheckOutTime, setValidCheckOutTime] = useState(null)
    const [globalMap, setGlobalMap] = useState(null)
    const [isNotWorkingDay, setIsNotWorkingDay] = useState(false);
    const [isAllowBuffer, setIsAllowBuffer] = useState(false);
    const [attendanceNotes, setAttendanceNotes] = useState([]);

    function userIcon() {
      return new Leaflet.Icon({
        iconUrl: user.avatar_url,
        iconSize: [50, 50], // size of the icon
        className: 'rounded-full border-2 border-white shadow-lg',
      });
    }

    useEffect(() => {
      setIsLoading(true);

      axios.get('/attendances')
        .then(function (response) {
          // handle success
          setToday(response.data.date)

          if(response.data.status === 'not_working_day') {
            setIsNotWorkingDay(true)
          } else {
            setValidCheckInTime(response.data.valid_checkin_time)
            setValidCheckOutTime(response.data.valid_checkout_time)
            setIsAllowBuffer(response.data.is_allow_buffer)
          }

          if(response.data.status === 'checked_in') {
            setIsWaitForCheckout(true)
            setAttendanceId(response.data.data.id)
            setAttendance(response.data.data)
          }
          
          if(response.data.status === 'checked_out') {
            setIsCheckedOut(true)
            setAttendanceId(response.data.data.id)
            setAttendance(response.data.data)
          }
          
          setIsInitialLoaded(true); 
        })
        .catch(function (error) {
          // handle error
          setIsLoading(false); 
        })
        .then(function () {
          setIsLoading(false); 
        });
    }, [])

    useEffect(() => {

      if(position) {
        setIsLoading(true);

        axios.post('/attendances/create', {
          lat: position.lat,
          lng: position.lng,
          is_checkout: isWaitForCheckout,
        })
          .then(function (response) {
            // handle success
            //console.log(response.data);  
            setIsInOffice(response.data.is_in_office);
            setIsTimeValid(response.data.is_time_valid);
            setAttendanceNotes(response.data.attendance_notes);
            setTimeout(function() {
              setIsLoading(false);
            }, 2000) 
          })
          .catch(function (error) {
            // handle error
            //setIsLoaded(true);
            //setError(error);
          })
          .then(function () {
            //setIsLoaded(true);
          });
        }
    }, [position])

    const eventHandlers = useMemo(
        () => ({
          dragend() {
            const marker = markerRef.current
            if (marker != null) {
              setPosition(marker.getLatLng())
            }
          },
        }),
        [],
    )

    const updateLocation = useCallback(() => {
      setIsLoading(true);
      globalMap.locate()
    }, [globalMap])

    function LocationMarker() {
        const map = useMapEvents({
          click() {
            map.locate()
          },
          load() {
            map.locate()
            console.log('load')
          },
          locationfound(e) {
            setPosition(e.latlng)
            map.flyTo(e.latlng, map.getZoom())
          },
          locationerror(e) {
            setIsLoading(false);
            toast.error('Make sure GPS is active');
          },
        })

        //if(!position) { map.locate() }
      
        return position === null ? null : (
          <Marker 
            position={position}
            eventHandlers={eventHandlers}
            draggable={false}
            ref={markerRef}
            icon={userIcon()}
        >
            {/* <Popup>Your location</Popup> */}
            <Tooltip direction="top" offset={[0, -30]} permanent>{user.name}</Tooltip>
          </Marker>
        )
    }

    function checkIn() {
      setIsLoading(true);

      axios.post('/attendances', {
        lat: position.lat,
        lng: position.lng,
        note: note,
        other_note: otherNote,
      })
        .then(function (response) {
          // handle success
          //console.log(response.data);  
          
          setPosition(null)
          setIsWaitForCheckout(true)
          setAttendanceId(response.data.data.id)
          setAttendance(response.data.data)
          
          toast.success('Checked in successfully!')

          setTimeout(function() {
            setIsLoading(false); 
          }, 500) 
        })
        .catch(function (error) {
          // handle error
          //setIsLoaded(true);
          //setError(error);
          setIsLoading(false); 
        })
        .then(function () {
          //setIsLoaded(true);
          setIsLoading(false); 
        });
    }

    function checkOut() {
      setIsLoading(true);

      axios.post('/attendances/'+attendanceId, {
        _method: 'put',
        lat: position.lat,
        lng: position.lng,
        note: note,
        other_note: otherNote,
      })
        .then(function (response) {
          // handle success
          //console.log(response.data);  
          
          setPosition(null)
          toast.success('Checked out successfully!')
          setAttendanceId(response.data.data.id)
          setAttendance(response.data.data)

          setTimeout(function() {
            setIsLoading(false); 
            setIsCheckedOut(true);
            
          }, 500) 
        })
        .catch(function (error) {
          // handle error
          //setIsLoaded(true);
          //setError(error);
          setIsLoading(false); 
        })
        .then(function () {
          //setIsLoaded(true);
          setIsLoading(false); 
        });
    }

    // Convert time to 12 hour format with am/pm
    function convertTime(time) {
      var hours = time.substr(0, 2);
      var minutes = time.substr(3, 2);
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }
 
    if(!isInitialLoaded) {
      return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
    } else {
      return (
        <div>
          <div className="h-[calc(100vh_-_275px)] relative">
              <MapContainer
                  style={{ height: '100%'}}
                  center={{ lat: user.office ? user.office.lat : 24.7428704, lng: user.office ? user.office.lng : 46.65558 }}
                  zoom={14}
                  scrollWheelZoom={false}
                  ref={setGlobalMap}>
                  <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <LocationMarker />
              </MapContainer>

              {position && <div className="absolute bottom-5 right-2 left-2 text-center" style={{ zIndex: 99999 }} ><button onClick={updateLocation} type="button" className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
                  <LocationMarkerIcon className="h-5 w-5 mr-2 text-indigo-400" aria-hidden="true" />
                  Reload location
              </button></div>}
          </div>

          {isNotWorkingDay && <div className="h-[275px] relative bg-white p-5 flex items-center justify-center"><div>
            <div className="mt-5 text-sm text-center text-gray-500">
              <p>Hi {user.name}!</p>
              <p>It's not your working day!</p>
            </div>
          </div></div>}

          {isCheckedOut && <div className="h-[275px] relative bg-white p-5 flex items-center justify-center"><div>
            {(attendanceId) && <div className="">
              <div className="mb-3">
                <div className="flex items-center space-x-1">
                  { attendance.is_checkin_time_valid && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
                  { !attendance.is_checkin_time_valid && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                  <span className="text-sm font-medium text-gray-500">Checked-in at {attendance.checkin_at}</span>
                </div>

                {attendance.office_id !== 1 && <div>
                  { (attendance.is_checkin_location_valid) && <div className="flex items-center space-x-1">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    <span className="text-sm font-medium text-gray-500">In office {attendance.office.name}</span>
                  </div>}

                  { !attendance.is_checkin_location_valid && <div className="flex items-center space-x-1">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    <span className="text-sm font-medium text-gray-500">Not in office {attendance.office.name}</span>
                  </div>}
                </div>}
              </div>
              <div className="">
                <div className="flex items-center space-x-1">
                  { attendance.is_checkout_time_valid && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
                  { !attendance.is_checkout_time_valid && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                  <span className="text-sm font-medium text-gray-500">Checked-out at {attendance.checkout_at}</span>
                </div>

                {attendance.office_id !== 1 && <div>
                  { (attendance.is_checkout_location_valid) && <div className="flex items-center space-x-1">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                    <span className="text-sm font-medium text-gray-500">In office {attendance.office.name}</span>
                  </div>}

                  { !attendance.is_checkout_location_valid && <div className="flex items-center space-x-1">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                    <span className="text-sm font-medium text-gray-500">Not in office {attendance.office.name}</span>
                  </div>}
                </div>}
              </div>
              <div className="mt-2">
                {attendance.checkout_at && <div>
                    { attendance.is_worked_valid && <div className="">
                        <div className="flex items-center space-x-1">
                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            <span className="text-sm font-medium text-green-500">
                            Hours Worked: {attendance.hours_worked} 
                            </span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <span className="text-sm font-medium text-gray-500">
                            {attendance.valid_working_hours} 
                            </span>
                            { attendance.worked_diff_in_seconds && <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-green-600">+{attendance.worked_diff_in_hours}</span>}
                        </div>
                        </div>}

                        { !attendance.is_worked_valid && <div className="">
                        <div className="flex items-center space-x-1">
                            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                            <span className="text-sm font-medium text-gray-500">
                            Hours Worked: {attendance.hours_worked} 
                            </span>
                        </div>
                        <div className="flex items-center space-x-1">
                            <span className="text-sm font-medium text-gray-500">
                            {attendance.valid_working_hours} 
                            </span>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-orange-600">-{attendance.worked_diff_in_hours}</span>
                        </div>
                        </div>}
                    </div>}
              </div>
            </div>}
            
            <div className="mt-5 text-sm text-center text-gray-500">
              <p>Goodbye {user.name}!</p>
              <p>Have a good day!</p>
            </div>
          </div></div>}

          {!isCheckedOut && <div className="h-[275px] relative bg-white p-4">

          {(attendanceId && !position) && <div>
            <div className="">
              <div className="flex items-center space-x-1">
                { attendance.is_checkin_time_valid && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />}
                { !attendance.is_checkin_time_valid && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />}
                <span className="text-sm font-medium text-gray-500">Checked-in at {attendance.checkin_at}</span>
              </div>

              {attendance.office_id !== 1 && <div>
                { (attendance.is_checkin_location_valid) && <div className="flex items-center space-x-1">
                  <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  <span className="text-sm font-medium text-gray-500">In office {attendance.office.name}</span>
                </div>}

                { !attendance.is_checkin_location_valid && <div className="flex items-center space-x-1">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  <span className="text-sm font-medium text-gray-500">Not in office {attendance.office.name}</span>
                </div>}
              </div>}
            </div>
          </div>}
          
            {/* {position && <div className="text-xs text-center text-gray-600 mb-3">Please always make sure GPS turned ON<br />And tap on the map to get your location</div>} */}
            
            {!position && <div className="text-center">
              {/* <div className="text-center flex justify-center mb-2"><img src="/tap.svg" className="w-10" /></div> */}
              <div className="mt-5 font-medium text-center text-black">
                To {isWaitForCheckout ? <span className="text-red-600">check-out</span> : <span className="text-green-600">check-in</span>} tap the below button
              </div>
              <div className="mt-1 font-medium text-xs text-center text-gray-500">
                {!isWaitForCheckout && <span>Your office start working hour is {validCheckInTime ? convertTime(validCheckInTime.substr(0, 2)+':'+validCheckInTime.substr(2, 2)) : null}</span>}
                {isWaitForCheckout && <span>Your office end working hour is {validCheckOutTime ? convertTime(validCheckOutTime.substr(0, 2)+':'+validCheckOutTime.substr(2, 2)) : null}</span>}
              </div>
              {isAllowBuffer && <div className="mt-1 font-medium text-xs text-center text-gray-500">
                {!isWaitForCheckout && <span>You're allowed to be late for 15 minutes</span>}
                {isWaitForCheckout && <span>You're allowed to go earlier 15 minutes</span>}
              </div>}
            </div>}
            {(isInOffice && position && !isLoading && !user.is_remote) && <div className="">
                <div className="flex items-center space-x-2">
                  <OfficeBuildingIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
                  <span className="font-medium text-sm text-gray-500">{ user.office.name }</span>
                </div>
                <div className="flex items-center space-x-2">
                  <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                  <span className="font-medium text-sm text-green-500">You're in the office location</span>
                </div>
            </div>}
            {(!isInOffice && position && !isLoading && !user.is_remote) && <div>
              <div className="">
                <div className="flex items-center space-x-2">
                  <OfficeBuildingIcon className="h-5 w-5 text-indigo-400" aria-hidden="true" />
                  <span className="font-medium text-sm text-gray-500">{ user.office.name }</span>
                </div> 
                <div className="flex items-center space-x-2">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  <span className="font-medium text-sm text-red-500">You're NOT in the office location</span>
                </div>  
            </div></div>}

            {!isTimeValid && position && !isLoading && <div className="flex items-center space-x-2">
                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                <span className="font-medium text-sm text-red-500">Your time is not valid</span>
              </div>}

            {(!isInOffice && position && !isLoading && !user.is_remote) && <div className="text-xs text-red-500 mt-2">You still can continue with in-correct office location</div>}


            { (position && !isLoading && ((!isInOffice && !user.is_remote) || !isTimeValid)) && <div className="mt-3">
                <div>
                  <select
                    onChange={(event) => setNote(event.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue=""
                  >
                    <option value="">No Reason Specified</option>
                    {attendanceNotes.map(attendanceNotes => (<option value={attendanceNotes.label}>{attendanceNotes.label}</option>))}
                    <option value="Other">Other</option>
                  </select>
                </div>
                {note === 'Other' && <div className="mt-2">
                  <textarea onChange={(event) => setOtherNote(event.target.value)} placeholder="Write your reason" rows="1" name="comment" id="comment" className="text-sm block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                </div>}
              </div>}

            { (position && isLoading) && <div className="mt-8 w-full">
              <div className="flex justify-center items-center w-full animate-pulse">
                <LocationMarkerIcon className="h-5 w-5 mr-2 text-indigo-400" aria-hidden="true" />Loading..
              </div>
            </div>}

            {(!isWaitForCheckout && position) && <button onClick={() => checkIn()}  type="button" disabled={position === null || isLoading} className="absolute bottom-4 left-4 right-4 inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75 disabled:bg-gray-600">
              <img
                  className="w-5 h-5 mr-2 bg-gray-300 rounded-full flex-shrink-0"
                  src={user.avatar_url}
                  alt=""
                />
              Check-in
            </button>}
            {(isWaitForCheckout && position) && <button onClick={() => checkOut()}  type="button" disabled={position === null || isLoading} className="absolute bottom-4 left-4 right-4 inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75 disabled:bg-gray-600">
              <img
                  className="w-5 h-5 mr-2 bg-gray-300 rounded-full flex-shrink-0"
                  src={user.avatar_url}
                  alt=""
                />
              Check-out
            </button>}

            {!position && <button onClick={() => updateLocation()}  type="button" disabled={isLoading} className="absolute bottom-5 left-5 right-5 inline-flex justify-center items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-0 disabled:opacity-75 disabled:bg-gray-600">
              <img
                  className="w-5 h-5 mr-2 bg-gray-300 rounded-full flex-shrink-0"
                  src={user.avatar_url}
                  alt=""
                />
              Get your location
            </button>}
          </div>}
          
          {/* <div>
              <pre>{JSON.stringify(position, 0, 2)}</pre>
          </div> */}
          {/* <button style={{ zIndex: 99999 }} type="button" className="fixed top-2 right-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0">
              <QuestionMarkCircleIcon className="h-5 w-5 mr-2 text-indigo-400" aria-hidden="true" />
              Need help?
          </button> */}

          { today && <div style={{ zIndex: 1000 }} className="text-sm font-medium p-2 fixed top-0 right-0 rounded-bl-lg shadow-sm bg-white border border-transparent flex items-center"><CalendarIcon className="h-5 w-5 mr-2 text-indigo-400" aria-hidden="true" />Today: {today}</div> }

          <ToastContainer theme="colored" position="top-center" />
        </div>
      )
    }
}