import Attendance from '../../components/Attendance';

export default function CreateCrud(props) {
  const { user } = props

  return (
    <div className="h-full">
      <Attendance user={user} />
    </div>
  )
}
