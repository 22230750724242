import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CheckCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';

// Routes
import Home from './features/home';

// Axios
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT
axios.defaults.withCredentials = true

// Global variables
const grandizerV1Url = process.env.REACT_APP_V1_URL

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState([]);
  const [loginUrl, setLoginUrl] = useState(null);

  // Set page title
  useEffect(() => {
    document.title = `Grandizer Attendance`;
  }, [])

  useEffect(() => {
    // Get user detail
    axios.get('/me')
      .then(function (response) {
        // handle success
        //console.log(response.data);
        if(response.data.status === 'not_logged_in') {
          setLoginUrl(grandizerV1Url+'/login?url='+window.location.href)
        } else {
        
          setUser(response.data.data);
          setIsLoaded(true);

        }
        
      })
      .catch(function (error) {
        // handle error
        //setIsLoaded(true);
        setError(error);
      })
      .then(function () {
        //setIsLoaded(true);
      });

      
  }, [])

  // function getMobileOperatingSystem() {
  //   var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   // Windows Phone must come first because its UA also contains "Android"
  //   if (/windows phone/i.test(userAgent)) {
  //       return "Windows Phone";
  //   }

  //   if (/android/i.test(userAgent)) {
  //       return "Android";
  //   }

  //   // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //       return "iOS";
  //   }

  //   return "unknown";
  // }

  if(!isLoaded) {
    if(loginUrl) {
      return <div className="p-5">
        {/* {(getMobileOperatingSystem() === 'iOS' || getMobileOperatingSystem() === 'unknown') && <div className="mb-5">
          <h1 className="font-bold mb-5">How to install app on iOS</h1>
          <div className="mb-5">
            <p className="text-sm mb-2 font-bold"><span className="">Step 1:</span> tap share button on bottom of Safari browser</p>
            <p><img src="/images/ios1.jpg" className="border border-gray-400 rounded-lg shadow-sm" /></p>
          </div>
          <div className="mb-5">
            <p className="text-sm mb-2 font-bold"><span className="">Step 2:</span> tap "Add to Home Screen" on the share menu</p>
            <p><img src="/images/ios2.jpg" className="border border-gray-400 rounded-lg shadow-sm" /></p>
          </div>
          <div className="mb-5">
            <p className="text-sm mb-2 font-bold"><span className="">Step 3:</span> tap "Add"</p>
            <p><img src="/images/ios3.jpg" className="border border-gray-400 rounded-lg shadow-sm" /></p>
          </div>
          <div className="mb-5">
            <p className="text-sm mb-2 font-bold"><span className="">Step 4:</span> Open the app added to home screen</p>
            <p><img src="/images/ios4.jpg" className="w-20 border border-gray-400 rounded-lg shadow-sm" /></p>
          </div>
        </div>}

        {(getMobileOperatingSystem() === 'Android' || getMobileOperatingSystem() === 'unknown') && <div className="mb-5">
          <h1 className="font-bold mb-5">How to install app on Android</h1>
          <div className="mb-5">
            <p className="text-sm mb-2 font-bold"><span className="">Step 1:</span> tap menu button on the top of Chrome browser</p>
            <p><img src="/images/android1.jpg" className="border border-gray-400 rounded-lg shadow-sm" /></p>
          </div>
          <div className="mb-5">
            <p className="text-sm mb-2 font-bold"><span className="">Step 2:</span> tap "Add to Home Screen"</p>
            <p><img src="/images/android2.jpg" className="border border-gray-400 rounded-lg shadow-sm" /></p>
          </div>
          <div className="mb-5">
            <p className="text-sm mb-2 font-bold"><span className="">Step 3:</span> tap "Add"</p>
            <p><img src="/images/android3.jpg" className="border border-gray-400 rounded-lg shadow-sm" /></p>
          </div>
          <div className="mb-5">
            <p className="text-sm mb-2 font-bold"><span className="">Step 4:</span> Open the app added to home screen</p>
            <p><img src="/images/android5.jpg" className="w-20 border border-gray-400 rounded-lg shadow-sm" /></p>
          </div>
        </div>} */}

        <div class="h-[80vh] relative mt-5 flex justify-center items-center">
          <div>
            <div className="text-sm text-center mb-2 font-medium text-gray-600">Grandizer Attendane</div>
            <a href={loginUrl} rel="nofollow" className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-0 disabled:opacity-75 disabled:bg-gray-600">
                Tap here to login
            </a>
          </div>
        </div>
      </div>
    } else {
      if(error) {
        return <div className="fullscreen-loading flex items-center justify-around p-10">We're sorry, something went wrong! Please pull down to refresh.</div>
      }
      return <div className="fullscreen-loading flex items-center justify-around">Loading..</div>
    }
  } else {
    if(!user.office && !user.is_remote) {
      return <div className="fullscreen-loading flex items-center p-10 text-sm">
        <div>
          <div className="mb-2 text-center">Welcome {user.name}!</div>
          <table>
            <tr>
              <td><CheckCircleIcon className="block h-5 w-5 text-green-400 mr-2" aria-hidden="true" /></td>
              <td>Make sure you have installed Grandizer App if you have not installed it.</td> 
            </tr>
            <tr>
              <td><CheckCircleIcon className="block h-5 w-5 text-green-400 mr-2" aria-hidden="true" /></td>
              <td>Make sure you allow the app to use GPS settings when you do check in / out.</td> 
            </tr>
            <tr>
              <td><CheckCircleIcon className="block h-5 w-5 text-green-400 mr-2" aria-hidden="true" /></td>
              <td>Make sure admin (HR) setup your office informaiton.</td> 
            </tr>
          </table>
        </div>
      </div>
    } else {
      return (
        <BrowserRouter>
          <div className="min-h-full">
            {/* Main column */}
            <div className="flex flex-col">
              <main className="flex-1">
                <Routes>
                    <Route exact path="/" element={<Home user={user} />} />
                </Routes>
              </main>
            </div>
          </div>
        </BrowserRouter>
      );
    }
  }
}

export default App;
